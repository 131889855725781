import React, { useEffect } from "react";
import ClientBounceImg1 from "../../assets/images/client-bounce-img1.jpg";
import ClientBounceImg2 from "../../assets/images/client-bounce-img2.jpg";
import ClientBounceImg3 from "../../assets/images/client-bounce-img3.jpg";
import ClientBounceImg4 from "../../assets/images/client-bounce-img4.jpg";
import { Link } from "react-router-dom";

const TextMarqueAboutSection = () => {
  useEffect(() => {
    document.querySelectorAll(".logos").forEach((logosContainer) => {
      const copy = logosContainer.querySelector(".logos-slide").cloneNode(true);
      logosContainer.appendChild(copy);
    });
  }, []);
  return (
    <>
      <div className="header_full_sec about_header_full_sec">
        <div className="position-relative">
          {/* <img
            className="client-bounce-img client-bounce-img1"
            src={ClientBounceImg1}
            alt="client-bounce-img1"
          />
          <img
            className="client-bounce-img client-bounce-img2"
            src={ClientBounceImg2}
            alt="client-bounce-img2"
          />
          <img
            className="client-bounce-img client-bounce-img3"
            src={ClientBounceImg3}
            alt="client-bounce-img3"
          />
          <img
            className="client-bounce-img client-bounce-img4"
            src={ClientBounceImg4}
            alt="client-bounce-img4"
          /> */}
          <div className="logos">
            <div className="logos-slide">
              <div className="ticker__item">
                <Link to="mailto:contact@eulogetabala.cg">contact@eulogetabala.cg</Link>
              </div>
              <div className="ticker__item ticker__item_dark">
                <Link to="tel:(+242)065298498">(+242) 06 529 84 98</Link>
              </div>
              <div className="ticker__item">
                <Link to="mailto:contact@eulogetabala.cg">contact@eulogetabala.cg</Link>
              </div>
              <div className="ticker__item ticker__item_dark">
                <Link to="tel:(+242)065298498">(+242) 06 529 84 98</Link>
              </div>
              <div className="ticker__item">
                <Link to="mailto:contact@eulogetabala.cg">contact@eulogetabala.cg</Link>
              </div>
              <div className="ticker__item ticker__item_dark">
                <Link to="tel:(+242)065298498">(+242) 06 529 84 98</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TextMarqueAboutSection;
