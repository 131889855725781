import React, { useEffect, useRef } from "react";
import AOS from "aos";
import MediaLineTop from "../../assets/images/media-line-top.svg";
import MediaLineBottom from "../../assets/images/media-line-bottom.svg";
import PolygonArrow from "../../assets/images/Polygon-arrow.svg";
import BoyImg from "../../assets/images/hello-1.png";
import ArrowMain from "../../assets/images/arrow-main.png";
import ClientImg1 from "../../assets/images/clients-img1.jpg";
import ClientImg2 from "../../assets/images/clients-img2.jpg";
import ClientImg3 from "../../assets/images/clients-img3.jpg";
import ClientImg4 from "../../assets/images/clients-img4.jpg";
import FlowerImg from "../../assets/images/flower.png";
import { Link } from "react-router-dom";

const SectionOne = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const workedBoxRef = useRef(null);
  const JessicaBiogiMainRef = useRef(null);
  const arrowRef = useRef(null);
  const layerRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (e) => {
      const ivalueX = (e.pageX * -1) / 60;
      const ivalueY = (e.pageY * -1) / 60;
      const cvalueX = (e.pageX * -1) / 80;
      const cvalueY = (e.pageY * -1) / 90;

      if (workedBoxRef.current) {
        workedBoxRef.current.style.transform = `translate3d(${ivalueX}px, ${ivalueY}px, 0)`;
      }
      if (JessicaBiogiMainRef.current) {
        JessicaBiogiMainRef.current.style.transform = `translate3d(${cvalueX}px, ${cvalueY}px, 0)`;
      }
      if (arrowRef.current) {
        arrowRef.current.style.transform = `translate3d(${cvalueX}px, ${cvalueY}px, 0)`;
      }
    };

    const layer = layerRef.current;
    if (layer) {
      layer.addEventListener("mousemove", handleMouseMove);
    }

    return () => {
      if (layer) {
        layer.removeEventListener("mousemove", handleMouseMove);
      }
    };
  }, []);
  return (
    <>
      {/* <!-- ====================================== Section One ===================================== --> */}
      <section className="section-main overflow-hidden" id="main">
        <div className="section-main-sub position-relative">
          <div className="media-main">
            <img src={MediaLineTop} alt="media-line-top" />
            <div>
              <Link to="mailto:contact@eulogetabala.cg">
                <svg
                  className="icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <g clipPath="url(#clip0_37_120)">
                    <path
                      d="M2.5 5.83333C2.5 5.39131 2.67559 4.96738 2.98816 4.65482C3.30072 4.34226 3.72464 4.16667 4.16667 4.16667H15.8333C16.2754 4.16667 16.6993 4.34226 17.0118 4.65482C17.3244 4.96738 17.5 5.39131 17.5 5.83333V14.1667C17.5 14.6087 17.3244 15.0326 17.0118 15.3452C16.6993 15.6577 16.2754 15.8333 15.8333 15.8333H4.16667C3.72464 15.8333 3.30072 15.6577 2.98816 15.3452C2.67559 15.0326 2.5 14.6087 2.5 14.1667V5.83333Z"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M2.5 5.83333L10 10.8333L17.5 5.83333"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_37_120">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </Link>
            </div>
           
           
            <div>
  <Link to="https://www.linkedin.com/in/euloge-tabala-374240298/" target="_blank" rel="noopener noreferrer">
    <svg
      className="icon"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_37_136)">
        <path
          d="M4.98 3.5C4.98 4.60457 4.10457 5.5 3 5.5C1.89543 5.5 1 4.60457 1 3.5C1 2.39543 1.89543 1.5 3 1.5C4.10457 1.5 4.98 2.39543 4.98 3.5ZM4.98 8.5H1.02V21H4.98V8.5ZM8.25 8.5H12.02V10.21C12.5395 9.26843 13.6686 8.5 15.24 8.5C18.01 8.5 19 10.265 19 13.078V21H15.02V13.75C15.02 12.339 14.6937 11.25 13.34 11.25C11.97 11.25 11.5 12.235 11.5 13.75V21H8.25V8.5Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_37_136">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </Link>
</div>

<div>
  <Link to="https://www.facebook.com/euloge.tabala" target="_blank" rel="noopener noreferrer">
    <svg
      className="icon"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_37_136)">
        <path
          d="M17.5 1.66666H2.5C2.03913 1.66666 1.66666 2.03913 1.66666 2.5V17.5C1.66666 17.9609 2.03913 18.3333 2.5 18.3333H10.8333V12.5H8.33334V10H10.8333V7.91666C10.8333 5.94999 12.2833 4.58333 14.0833 4.58333H16.6667V7.08333H15C14.0792 7.08333 13.3333 7.82916 13.3333 8.75V10H16.6667L16.25 12.5H13.3333V18.3333H17.5C17.9609 18.3333 18.3333 17.9609 18.3333 17.5V2.5C18.3333 2.03913 17.9609 1.66666 17.5 1.66666Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_37_136">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </Link>
</div>

<div>
  <Link to="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer">
    <svg
      className="icon"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_37_136)">
        <path
          d="M10.0001 1.66667C5.40008 1.66667 1.66675 5.4 1.66675 10C1.66675 11.7194 2.15008 13.3367 3.01675 14.6867L1.66675 18.3333L5.45008 17.0333C6.80008 17.85 8.35008 18.3333 10.0001 18.3333C14.6001 18.3333 18.3334 14.6 18.3334 10C18.3334 5.4 14.6001 1.66667 10.0001 1.66667ZM10.0001 16.6667C8.56675 16.6667 7.23341 16.2333 6.15008 15.5L4.28341 16.15L4.96675 14.3333C4.15008 13.3 3.66675 11.9667 3.66675 10.5C3.66675 6.71667 6.71675 3.66667 10.5001 3.66667C14.2834 3.66667 17.3334 6.71667 17.3334 10.5C17.3334 14.2833 14.2834 16.6667 10.5001 16.6667H10.0001ZM12.7334 13.0333C12.4167 12.8333 11.8334 12.5 11.6667 12.4667C11.5001 12.4333 11.3501 12.4 11.2001 12.6333C11.0501 12.8667 10.5501 13.4333 10.3667 13.6C10.1834 13.7667 10.0001 13.8 9.68341 13.6C9.36675 13.4 8.55008 13.1333 7.71675 12.3167C6.88341 11.5 6.61675 10.6833 6.41675 10.3667C6.21675 10.05 6.26675 9.86667 6.45008 9.7C6.61675 9.55 6.78341 9.33333 6.93341 9.18333C7.08341 9.03333 7.13341 8.91667 7.30008 8.83333C7.46675 8.75 7.65008 8.8 7.83341 8.96667C8.01675 9.13333 8.53341 9.76667 8.61675 9.86667C8.70008 9.96667 8.78341 10.0333 8.71675 10.2333C8.65008 10.4333 8.43341 10.7333 8.33341 10.9C8.23341 11.0667 8.18341 11.2 8.36675 11.4333C8.55008 11.6667 9.08341 12.3667 9.63341 12.65C10.1834 12.9333 10.3501 12.9667 10.5501 12.8667C10.7501 12.7667 11.1001 12.4167 11.2167 12.2333C11.3334 12.05 11.4334 12.0667 11.6667 12.15C11.9001 12.2333 12.5001 12.5167 12.7167 12.65C12.9334 12.7833 13.0501 12.8333 13.1001 12.8667C13.1501 12.9 13.2001 12.9333 13.2001 13.0333C13.2001 13.1333 13.0501 13.2333 12.7334 13.0333Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_37_136">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </Link>
</div>


            <img src={MediaLineBottom} alt="media-line-bottom" />
          </div>
          <div className="container2 position-relative overflow-hidden">
            <p className="main-text" data-aos="fade-down">
              Bonjour<span>👋</span>, Je suis 
            </p>
            <h1>
              <mark>Euloge TABALA</mark> 
            </h1>
          
            <div className="home-girl-img-main">
              <div className="position-relative" ref={layerRef}>
                <div className="JessicaBiogi-main" data-aos="fade-down">
                  <div
                    className="position-relative"
                    id="JessicaBiogi_main"
                    ref={JessicaBiogiMainRef}
                  >
                    <img
                      className="JessicaBiogi-svg"
                      src={PolygonArrow}
                      alt="Polygon-arrow"
                    />
                    <p className="jessica-biogi">Euloge TABALA</p>
                  </div>
                </div>
                <img
                  className="home-girl-img"
                  src={BoyImg}
                  alt="Boy"
                  data-aos="fade-up"
                />
                <img
                  className="arrow-main"
                  id="arrow"
                  src={ArrowMain}
                  alt="arrow-main"
                  ref={arrowRef}
                />
                <div id="worked_box" ref={workedBoxRef}>
                  <div className="worked-box" data-aos="fade-right">
                    <p className="worked-more">
                     Je travail avec plus de 10 entreprises
                    </p>
                    <div className="client-img-main position-relative">
                      <img
                        className="client-img client-img1"
                        src={ClientImg1}
                        alt="clients-img1"
                      />
                      <img
                        className="client-img client-img2"
                        src={ClientImg2}
                        alt="clients-img2"
                      />
                      <img
                        className="client-img client-img3"
                        src={ClientImg3}
                        alt="clients-img3"
                      />
                      <img
                        className="client-img client-img4"
                        src={ClientImg4}
                        alt="clients-img4"
                      />
                      <p className="worked-more worked-more2">10+ Clients</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-main-right-contain overflow-hidden">
            <div
              className="section-main-right-contain-sub"
              data-aos="fade-left"
            >
              <h2 className="total-project">05+ ANS</h2>
              <p className="project-complted">D'EXPERIENCE</p>
            </div>
            <div className="flower-box" data-aos="fade-left">
              <img className="flower" src={FlowerImg} alt="flower" />
              <h2 className="Freelance">
               <span>Software Engeener résidant au CONGO.</span>  spécialisé dans la création de solutions logiciels sur mésure. 
                
              </h2>
            </div>
            <div className="mouse-scroll-box">
              <Link to="#section-two" className="mouse-main">
                <div className="mouse">
                  <svg
                    className="mouse-arrow-down"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="40"
                    viewBox="0 0 24 40"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_37_162)">
                      <path
                        d="M12 5L12 35"
                        stroke="#FFDB59"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16 31L12 35"
                        stroke="#FFDB59"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8 31L12 35"
                        stroke="#FFDB59"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_37_162">
                        <rect width="24" height="40" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <h2 className="scroll-down">SCROLLER</h2>
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ====================================== Section One End ===================================== --> */}
    </>
  );
};
export default SectionOne;
