import React, { useEffect } from "react";

const MarqueSection = () => {
  useEffect(() => {
    document.querySelectorAll(".logos").forEach((logosContainer) => {
      const copy = logosContainer.querySelector(".logos-slide").cloneNode(true);
      logosContainer.appendChild(copy);
    });
  }, []);
  return (
    <>
      {/* <!-- ====================================== User Branding Marquee Section ===================================== --> */}
      <div className="header_full_sec overflow-hidden">
        <div className="logos">
          <div className="logos-slide">
            <div className="ticker__item">Développement web</div>
            <div className="ticker__item ticker__item_dark">
            Développement mobile
            </div>
            <div className="ticker__item">UI/UX Design</div>
            <div className="ticker__item ticker__item_dark">Consulting</div>
            <div className="ticker__item">Formation</div>
            <div className="ticker__item ticker__item_dark">
              Intégration API
            </div>
            <div className="ticker__item">Maintenance</div>
            <div className="ticker__item ticker__item_dark">Gestion Email</div>
            <div className="ticker__item">Wordpress</div>

          </div>
        </div>
      </div>
      {/* <!-- ====================================== User Branding Marquee Section ===================================== --> */}
    </>
  );
};
export default MarqueSection;
