import React, { useEffect, useState } from "react";
import BiogiLogo from "../assets/images/logo-euloge.svg";
import { Link, NavLink, useLocation } from "react-router-dom";
import Resume from "../assets/pdf/new-cv-2.pdf";

const Header = () => {
  const location = useLocation();
  const [activeItem, setActiveItem] = useState("/");
  const [isFixed, setIsFixed] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [subActiveIndex, setSubActiveIndex] = useState(null);

  useEffect(() => {
    setActiveItem(location.pathname);
  }, [location.pathname]);

  const isActive = (path) => (activeItem === path ? "menu-active" : "");

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    if (scrollPosition >= 20) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
    setSubActiveIndex(null); // Reset sub menu when main menu is toggled
  };

  const toggleSubAccordion = (index) => {
    setSubActiveIndex(subActiveIndex === index ? null : index);
  };
  return (
    <>
      {/* <!-- ====================================== Main Menu ===================================== --> */}
      <header
        id="top-navbar"
        className={`top-navbar ${isFixed ? "fixed" : ""}`}
      >
        <div className="container">
          <div className="top-navbar_full">
            <div className="back-btn">
              <Link to="/" >
                <img src={BiogiLogo} alt="logo" />
              </Link>
            </div>
            <div className="hell">
              <ul className="top-navbar-title">
                <li>
                  <NavLink to="/" activeClassName="active">
                    Accueil
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/about" activeClassName="active">
                    Qui suis-je ?
                  </NavLink>
                </li>
                <li>
                <NavLink to="/services2" activeClassName="active">
                  Services
                  </NavLink>
                </li>
                <li>
                <NavLink to="/portfolio2" activeClassName="active">
                  Portfolio
                  </NavLink>
                </li>
                
               
      
                <li>
                  <Link to="/contact">
                    {" "}
                    <NavLink to="/contact" activeClassName="active">
                      Contact
                    </NavLink>
                  </Link>
                </li>
              </ul>
              <Link to={Resume} rel="noreferrer" target="_blank">
                <div className="cv-btn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_25_74)">
                      <path
                        d="M12 3V15"
                        stroke="#0F141C"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16 11L12 15L8 11"
                        stroke="#0F141C"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3 12C3 14.3869 3.94821 16.6761 5.63604 18.364C7.32387 20.0518 9.61305 21 12 21C14.3869 21 16.6761 20.0518 18.364 18.364C20.0518 16.6761 21 14.3869 21 12"
                        stroke="#0F141C"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                  </svg>
                </div>
              </Link>
              <div className="outer-menu">
                <input
                  className="checkbox-toggle"
                  type="checkbox"
                  name="checkbox"
                />
                <div className="hamburger">
                  <div></div>
                </div>
                {/* <!-- ====================================== Side Menu ===================================== --> */}
                <div className="menu">
                  <div>
                    <nav>
                      <div className="container">
                        <div className="row sidemenu-main">
                          <div className="col-lg-2">
                            <div className="menu-text">
                              <div className="side-menu-text">
                                <p>MENU</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-7 side-menu-scroll">
                            <div className="heading2">
                              <Link to="/">Accueil</Link>
                            </div>
                            <div className="heading2">
                              <Link to="/about">Qui suis-je ?</Link>
                            </div>
                            <div className="heading2">
                              <Link to="/services2">Services</Link>
                            </div>
                            <div className="heading2">
                              <Link to="/portfolio2">Portfolio</Link>
                            </div>
                            <div className="heading2">
                              <Link to="/contact">Contact</Link>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="cont-info">
                           
                              <div className="item">
                                <p className="sub-title-address">Me contacter</p>
                                <p>
                                  <Link to="/mailto:contact@eulogetabala.cg">
                                    contact@eulogetabala.cg
                                  </Link>
                                </p>
                                <p className="underline">
                                  <Link to="tel:+242065298498">
                                    +242 06 529 84 98
                                  </Link>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </nav>
                  </div>
                </div>
                {/* <!-- ====================================== Side Menu End===================================== --> */}
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- ====================================== Main Menu End===================================== --> */}
    </>
  );
};
export default Header;
