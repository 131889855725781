import React, { useEffect, useState } from "react";
import AOS from "aos";
import { Link } from "react-router-dom";

const SectionThree = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const data = [
    {
      title: "Education",
      content: (
        <>
          <div className="education position-relative">
            <div className="side_circle_ring">
              <div className="small_yellow_border">
                <div className="small_yellow_circle"></div>
              </div>
            </div>
            <div className="small_yellow_border_main">
              <p className="bachelor">
                Master en administration des bases de données
              </p>
              <p className="university">EAD / 2019 - 2020</p>
            </div>
          </div>
          <div className="education position-relative">
            <div className="side_circle_ring">
              <div className="small_yellow_border">
                <div className="small_yellow_circle"></div>
              </div>
            </div>
            <div className="small_yellow_border_main">
              <p className="bachelor">Licence en réseau et télécommunication</p>
              <p className="university">ISTP / 2015 - 2018</p>
            </div>
          </div>
          <div className="education position-relative">
            <div className="side_circle_ring">
              <div className="small_yellow_border">
                <div className="small_yellow_circle"></div>
              </div>
            </div>
            <div className="small_yellow_border_main">
              <p className="bachelor">BACCALAUREAT</p>
              <p className="university">CAMARA LAYE / 2011 - 2015</p>
            </div>
          </div>
          <div className="education position-relative">
            <div className="side_circle_ring">
              <div className="small_yellow_border">
                <div className="small_yellow_circle"></div>
              </div>
            </div>
            <div className="small_yellow_border_main">
              <p className="bachelor">
                MASTERING BACKEND WITH NODEJS, EXPRESS, MYSQL
              </p>
              <p className="university">ELEPHORM / 2021</p>
            </div>
          </div>
          <div className="education position-relative">
            <div className="side_circle_ring">
              <div className="small_yellow_border">
                <div className="small_yellow_circle"></div>
              </div>
            </div>
            <div className="small_yellow_border_main">
              <p className="bachelor">API REST SPECIALISATION</p>
              <p className="university">UDEMY / 2022</p>
            </div>
          </div>
          <div className="education position-relative">
            <div className="side_circle_ring">
              <div className="small_yellow_border">
                <div className="small_yellow_circle"></div>
              </div>
            </div>
            <div className="small_yellow_border_main">
              <p className="bachelor">REACT NATIVE FULLSTACK</p>
              <p className="university">UDEMY / 2021</p>
            </div>
          </div>
          <div className="education position-relative">
            <div className="side_circle_ring">
              <div className="small_yellow_border">
                <div className="small_yellow_circle"></div>
              </div>
            </div>
            <div className="small_yellow_border_main">
              <p className="bachelor">
                DATA ANALYSIS WITH TABLEAU, MYSQL, PYTHON, EXCEL
              </p>
              <p className="university">UDEMY / 2022</p>
            </div>
          </div>
          <div className="education position-relative">
            <div className="side_circle_ring">
              <div className="small_yellow_border">
                <div className="small_yellow_circle"></div>
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Experience",
      content: (
        <>
          <div className="education position-relative">
            <div className="side_circle_ring">
              <div className="small_yellow_border">
                <div className="small_yellow_circle"></div>
              </div>
            </div>
            <div className="small_yellow_border_main">
              <p className="bachelor">Développeur FullStack Senior</p>
              <p className="university">Smartvision / 2021 à Aujourd'hui</p>
              <p className="cursus">
                Optimisation des fonctionnalités des plateformes web Vision
                globale des objectifs d’accessibilité, d’attractivité, de
                référencement, d’ergonomie et de légitimité Anticipation et
                proactivité en matière de stratégie web corporate, Elaboration
                des documentations et fiches techniques Veille et validation du
                code pour garantir la compatibilité et la sécurité des
                navigateurs, serveurs et systèmes d’exploitation; Mise en place
                des fonctionnalités backend(Gestion de rôles et autres..).
              </p>
            </div>
          </div>
          <div className="education position-relative">
            <div className="side_circle_ring">
              <div className="small_yellow_border">
                <div className="small_yellow_circle"></div>
              </div>
            </div>
            <div className="small_yellow_border_main">
              <p className="bachelor">Chef de projet web zone Afrique</p>
              <p className="university">
                EKOMEDIA(FRANCE) / Février 2024 - Août 2024
              </p>
              <p className="cursus">
                Coordination des projets web : Gestion de projets web pour les
                clients basés en Afrique, depuis la planification initiale
                jusqu'à la livraison finale, en veillant à ce que les délais et
                les objectifs soient respectés. Communication inter-équipes :
                Collaboration avec les équipes techniques, les designers et les
                développeurs pour assurer la cohérence des projets et le respect
                des spécifications fonctionnelles. Gestion des ressources :
                Allocation et supervision des ressources nécessaires pour chaque
                projet, tout en maintenant une efficacité opérationnelle
                maximale. Suivi de production : Mise en place de tableaux de
                bord pour le suivi des tâches, des performances et de
                l’avancement des projets, afin de garantir une visibilité claire
                à toutes les parties prenantes. Relation client : Communication
                directe avec les clients pour la définition des besoins, les
                mises à jour régulières et la gestion des retours, assurant un
                haut niveau de satisfaction. Optimisation des sites : Veille à
                l’optimisation des performances des sites (temps de chargement,
                SEO, accessibilité) et proposition de solutions pour améliorer
                l’expérience utilisateur. Déploiement et maintenance :
                Supervision des phases de test, de déploiement et de maintenance
                des sites web, tout en assurant un support technique et une
                résolution rapide des incidents. Ce rôle m’a permis de gérer
                efficacement plusieurs projets en parallèle tout en contribuant
                à l'expansion d'EKOMEDIA sur le marché africain grâce à des
                solutions web sur mesure et innovantes..
              </p>
            </div>
          </div>
          <div className="education position-relative">
            <div className="side_circle_ring">
              <div className="small_yellow_border">
                <div className="small_yellow_circle"></div>
              </div>
            </div>
            <div className="small_yellow_border_main">
              <p className="bachelor">DEVELOPPEUR MOBILE CONSULTANT</p>
              <p className="university">GLOBAL TECH / 2018 - 2019</p>
              <p className="cursus">
                Fonction généraliste dans la conception et le développement des
                applications mobiles Elaboration de projets au sein d’une équipe
                pluridisciplinaire en web et mobile Réalisations des UI & UX
                design avec Figma Contacts clients, analyse et traduction IT des
                besoins Planification de projets et planning éditoriaux
              </p>
            </div>
          </div>
          <div className="education position-relative">
            <div className="side_circle_ring">
              <div className="small_yellow_border">
                <div className="small_yellow_circle"></div>
              </div>
            </div>
            <div className="small_yellow_border_main">
              <p className="bachelor">CHEF DE PROJETS IT</p>
              <p className="university">AITEC / 2017 - 2019</p>
              <p className="cursus">
                Identifier les objectifs, les exigences et les spécifications du
                projet en consultation avec les parties prenantes. Élaborer un
                plan détaillé du projet, y compris la répartition des tâches,
                les échéances, les ressources nécessaires et le budget. Affecter
                et gérer les membres de l'équipe du projet, y compris les
                développeurs, les testeurs, les concepteurs, etc. Surveiller en
                continu l'avancement du projet par rapport au plan initial et
                apporter des ajustements si nécessaire. Identifier les risques
                potentiels et mettre en place des stratégies pour les minimiser
                ou les gérer. Maintenir des registres, des rapports et des
                documents de projet pour suivre les activités, les décisions et
                les problèmes. Superviser les tests et l'assurance qualité pour
                garantir que le produit final respecte les normes de qualité.
                Suivre les dépenses du projet et s'assurer que le budget est
                respecté. Fournir une formation et un soutien aux membres de
                l'équipe et aux utilisateurs finaux.
              </p>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Mon Expertise",
      content: (
        <>
         
          <div className="education position-relative">
            <div className="side_circle_ring">
              <div className="small_yellow_border">
                <div className="small_yellow_circle"></div>
              </div>
            </div>
            <div className="small_yellow_border_main">
              <p className="bachelor">
              Développement Web 
              </p>
             
             
            </div>
          </div>
          <div className="education position-relative">
            <div className="side_circle_ring">
              <div className="small_yellow_border">
                <div className="small_yellow_circle"></div>
              </div>
            </div>
            <div className="small_yellow_border_main">
              <p className="bachelor">
                Développement mobile
              </p>
             
            </div>
          </div>
          <div className="education position-relative">
            <div className="side_circle_ring">
              <div className="small_yellow_border">
                <div className="small_yellow_circle"></div>
              </div>
            </div>
            <div className="small_yellow_border_main">
              <p className="bachelor">
                Analyse de données
              </p>
             
            </div>
           
          </div>
          <div className="education position-relative">
            <div className="side_circle_ring">
              <div className="small_yellow_border">
                <div className="small_yellow_circle"></div>
              </div>
            </div>
            <div className="small_yellow_border_main">
              <p className="bachelor">
                Administration base de données
              </p>
             
            </div>
           
          </div>
          <div className="education position-relative">
            <div className="side_circle_ring">
              <div className="small_yellow_border">
                <div className="small_yellow_circle"></div>
              </div>
            </div>
            <div className="small_yellow_border_main">
              <p className="bachelor">
                Formation
              </p>
             
            </div>
           
          </div>
        </>
      ),
    },
    
  ];

  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <>
      {/* <!-- ====================================== About Section Three ===================================== --> */}
      <section className="section-three overflow-hidden" id="explore-me">
        <div className="container">
          <div className="row services-section">
            <div className="col-xxl-4 col-xl-4 col-lg-4">
              <p className="our-services" data-aos="fade-up">
                INFORMATIONS PERSONNELLES
              </p>
              <h2 className="our-solution" data-aos="fade-up">
                Me découvrir.
              </h2>
              <p className="uran odio" data-aos="fade-up">
                Mon parcours et mes réalisations sont là détaillés pour vous
                donner un aperçu réel de qui je suis !
              </p>
              <div className="services-circle-main">
                <div
                  className="view-work-circle btn btn1"
                  id="circle1"
                  data-aos="zoom-in-right"
                >
                  <Link to="/portfolio2" className="a1">
                    MES PROJETS
                  </Link>
                </div>
                <div className="contact-circle" data-aos="zoom-in-left">
                  <div className="view-work-circle btn btn2">
                    <Link to="/contact">ME CONTACTER</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-7 col-xl-7 col-lg-7">
              <div id="faq-sec">
                {data.map((item, index) => (
                  <div
                    className="nested-accordion"
                    key={index}
                    data-aos="fade-up"
                  >
                    <h3
                      className={`boder-top ${
                        activeIndex === index ? "selected" : ""
                      }`}
                      onClick={() => handleClick(index)}
                    >
                      {item.title}
                    </h3>
                    <div
                      className="comment"
                      style={{
                        display: activeIndex === index ? "block" : "none",
                      }}
                    >
                      {item.content}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ====================================== About Section Three End ===================================== --> */}
    </>
  );
};
export default SectionThree;
