import React, { useEffect, useState } from "react";
import AOS from "aos";
import Loader from "../../../common/Loader";
import Header from "../../../common/Header";
import { Link } from "react-router-dom";
import SingleServicesImg1 from "../../../assets/images/port1.png";
import SingleServicesImg2 from "../../../assets/images/port2.png";
import SingleServicesImg3 from "../../../assets/images/port3.png";
import SingleServicesImg4 from "../../../assets/images/port4.png";
import SingleServicesImg5 from "../../../assets/images/port5.png";
import SingleServicesImg6 from "../../../assets/images/port6.png";
import SingleServicesImg7 from "../../../assets/images/port7.png";
import SingleServicesImg8 from "../../../assets/images/port8.png";
import SingleServicesImg9 from "../../../assets/images/port9.png";
import SingleServicesImg10 from "../../../assets/images/port10.png";

import BrandsMarqueSection from "../../../common/BrandsMarqueSection";
import TextMarqueAboutSection from "../../About/TextMarqueAboutSection";
import Footer from "../../../common/Footer";
import ProgressCircle from "../../../common/ProgressCircle";

const PortfolioPageTwo = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  return (
    <>
      <Loader />
      <div className="site_contain">
        {/* <!-- ====================================== Header Menu ===================================== --> */}

        {/* <!-- ====================================== Header Menu End===================================== --> */}
        {/* <!-- ====================================== Portfolio Section One ===================================== --> */}
        <section
          className="single-blog2-section-main overflow-hidden"
          id="main"
        >
          <div className="container">
            <p className="main-text" data-aos="fade-down">
              Mon portfolio
            </p>
            <h1 className="JessicaBiogi blog2-main-text" data-aos="fade-down">
              Réalisations
            </h1>
            <nav aria-label="breadcrumb" id="breadcrumb" data-aos="fade-up">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Accueil</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <Link to="/portfolio2">Portfolio</Link>
                </li>
              </ol>
            </nav>
          </div>
        </section>
        {/* <!-- ====================================== Portfolio Section One End ===================================== --> */}
        {/* <!-- ================== Portfolio Section Two ===============================--> */}
        <section className="section-five section-five-portfolio overflow-hidden">
          <div className="container">
            <p className="our-services discover-our-cases" data-aos="fade-up">
              DECOUVRIR MES PROJETS
            </p>
            <div className="tab-header">
              <div>
                <h2
                  className="our-solution latest_project"
                  data-aos="fade-down"
                >
                  Les dernieres réalisations.
                </h2>
              </div>
            </div>
            <div className="tab-content">
              <div
                id="tab1"
                className={`tab-pane tab-pane2 ${
                  activeTab === "tab1" ? "active" : ""
                }`}
                data-aos="zoom-in"
              >
                <div className="row">
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 portfolio-hover-box-main">
                    <div className="portfolio-hover-box">
                      <Link
                        to="#"
                        onClick={() =>
                          window.open("https://amc-assurances.com/", "_blank")
                        }
                      >
                        <img
                          src={SingleServicesImg1}
                          alt="single-services-project-img1"
                        />
                        <div className="figcaption">
                          <h3>AMC Assurance</h3>
                          <h4>VISITER</h4>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 portfolio-hover-box-main">
                    <div className="portfolio-hover-box">
                      <Link to="#"
                      onClick={() =>
                        window.open("https://equitycapitalcongo.com/", "_blank")
                      }
                      >
                        <img
                          src={SingleServicesImg2}
                          alt="single-services-project-img2"
                        />
                        <div className="figcaption">
                          <h3>Equity-Capital</h3>
                          <h4>VISITER</h4>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 portfolio-hover-box-main">
                    <div className="portfolio-hover-box">
                      <Link to="#"
                      onClick={() =>
                        window.open("https://mp-services.net/", "_blank")
                      }
                      >
                        <img
                          src={SingleServicesImg3}
                          alt="single-services-project-img3"
                        />
                        <div className="figcaption">
                          <h3>Mp-Services</h3>
                          <h4>VISITER</h4>
                        </div>
                      </Link>
                    </div>
                  </div>

                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 portfolio-hover-box-main">
                    <div className="portfolio-hover-box">
                      <Link to="#"
                      onClick={() =>
                      window.open("https://budget.gouv.cg/", "_blank")
                      }
                      >
                        <img
                          src={SingleServicesImg4}
                          alt="single-services-project-img3"
                        />
                        <div className="figcaption">
                          <h3>Ministère du Budget</h3>
                          <h4>VISITER</h4>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 portfolio-hover-box-main">
                    <div className="portfolio-hover-box">
                      <Link to="#"
                       onClick={() =>
                        window.open("https://bms.cg/", "_blank")
                        }
                      >
                        <img
                          src={SingleServicesImg5}
                          alt="single-services-project-img3"
                        />
                        <div className="figcaption">
                          <h3>BMS</h3>
                          <h4>VISITER</h4>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 portfolio-hover-box-main">
                    <div className="portfolio-hover-box">
                      <Link to="#"
                        onClick={() =>
                          window.open("https://polyglotte-interpretariat.com/", "_blank")
                          }
                      >
                        <img
                          src={SingleServicesImg6}
                          alt="single-services-project-img3"
                        />
                        <div className="figcaption">
                          <h3>Polyglotte-interpretariat</h3>
                          <h4>VISITER</h4>
                        </div>
                      </Link>
                    </div>
                  </div>

                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 portfolio-hover-box-main">
                    <div className="portfolio-hover-box">
                      <Link to="#"
                      onClick={() =>
                        window.open("https://gramat.cg/", "_blank")
                        }
                      >
                        <img
                          src={SingleServicesImg8}
                          alt="single-services-project-img3"
                        />
                        <div className="figcaption">
                          <h3>Gramat Construction</h3>
                          <h4>VISITER</h4>
                        </div>
                      </Link>
                    </div>
                  </div>

                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 portfolio-hover-box-main">
                    <div className="portfolio-hover-box">
                      <Link to="#"
                       onClick={() =>
                        window.open("https://hub-distribution.com/", "_blank")
                        }
                      >
                        <img
                          src={SingleServicesImg7}
                          alt="single-services-project-img3"
                        />
                        <div className="figcaption">
                          <h3>Hub Distribution</h3>
                          <h4>VISITER</h4>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 portfolio-hover-box-main">
                    <div className="portfolio-hover-box">
                      <Link to="#"
                        onClick={() =>
                          window.open("https://bch.cg/", "_blank")
                          }
                      >
                        <img
                          src={SingleServicesImg9}
                          alt="single-services-project-img3"
                        />
                        <div className="figcaption">
                          <h3>Banque Congolaise de l'Habitat</h3>
                          <h4>VISITER</h4>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 portfolio-hover-box-main">
                    <div className="portfolio-hover-box">
                      <Link to="#"
                        onClick={() =>
                          window.open("https://docsenglish.cg/", "_blank")
                          }
                      >
                        <img
                          src={SingleServicesImg10}
                          alt="single-services-project-img3"
                        />
                        <div className="figcaption">
                          <h3>Doc's english</h3>
                          <h4>VISITER</h4>
                        </div>
                      </Link>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- ================== Portfolio Section Two End ===============================--> */}
        {/* <!-- ====================================== Brands Marquee Section Start ===================================== --> */}
        <BrandsMarqueSection />
        {/* <!-- ====================================== Brands Marquee Section End ===================================== --> */}
        <TextMarqueAboutSection />
        {/* <!-- ====================================== text Marquee Section End ===================================== --> */}
        {/* <!-- ====================================== Footer ===================================== --> */}
       
        {/* <!-- ====================================== Progress Scroll Bottom To Top ===================================== --> */}
        <ProgressCircle />
        {/* <!-- ====================================== Progress Scroll Bottom To Top End ===================================== --> */}
      </div>
    </>
  );
};
export default PortfolioPageTwo;
