import React, { useEffect } from "react";
import AOS from "aos";
import TrophyImg from "../../assets/images/trophy.png";

const SectionTwo = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <>
      {/* <!-- ====================================== About Section Two ===================================== --> */}
      <section className="about-section-two overflow-hidden">
        <div className="container">
          <div
            className="award-circle-main aos-init aos-animate"
            data-aos="zoom-in"
          >
            <div className="award-circle">
              <img src={TrophyImg} alt="trophy" />
            </div>
            <div className="">
                  <h3 className="independent">Me découvrir un peu plus</h3>
                  <p className="annual">Qui suis-je?</p>
                </div>
          </div>
          <h5 className="self-taught" data-aos="fade-up">
                Je suis Euloge Tabala ingénieur logiciel avec plus de 5 ans
                d'expérience dans le développement de solutions numériques
                innovantes. Spécialisé dans la conception et la mise en œuvre de
                projets web et mobiles, je maîtrise divers frameworks et
                technologies pour créer des applications robustes et
                performantes. Mon expertise me permet de naviguer aisément à
                travers différentes phases du développement, tout en apportant
                une valeur ajoutée à chaque projet sur lequel je travail.
              </h5>
          {/* <p className="odio aos-init aos-animate" data-aos="fade-up">
            Award-winning design director and design expert with 6 years of
            experience in design and management. I am a mom to a beautiful 2
            y.o. daughter who already loves attending business meetings with me.
            Also, a big part of my life is Netrix - a design creator behind this
            blog, artistic aesthetics into her healthy meals and making her
            recipes into artwork. She has always nurtured an interest in healthy
            eating and loves creating meals that are good for her body while
            being creative with simple ingredients. The creator behind this
            blog, enjoys incorporating her and making her recipes into artwork.
            She has always nurtured an interest in healthy eating and loves
            creating meals.
          </p> */}
        </div>
      </section>
      {/* <!-- ====================================== About Section Two End ===================================== --> */}
    </>
  );
};
export default SectionTwo;
