import React, { useEffect, useState } from "react";
import AOS from "aos";
import Loader from "../../common/Loader";
import Header from "../../common/Header";
import PhoneSvg from "../../assets/images/phone_svg.svg";
import EmailSvg from "../../assets/images/email_svg.svg";
import LocationSvg from "../../assets/images/location_svg.svg";
import TextMarqueAboutSection from "../About/TextMarqueAboutSection";
import Footer from "../../common/Footer";
import Cursor from "../../common/Cursor";
import { Link } from "react-router-dom";
import emailjs from "emailjs-com";

function ContactForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: ""
  });

  // Mettre à jour les champs de formulaire
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Envoyer l'e-mail avec EmailJS
  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.send(
      "service_sxizwop", // Remplacez par votre Service ID
      "template_ihzpg3m", // Remplacez par votre Template ID
      formData,
      "ElVIWfk4-F82Y3h2A" // Remplacez par votre User ID
    )
    .then((response) => {
      console.log("Email envoyé avec succès !", response.status, response.text);
      alert("Votre message a été envoyé avec succès !");
    })
    .catch((error) => {
      console.error("Erreur lors de l'envoi de l'email :", error);
      alert("Une erreur s'est produite lors de l'envoi du message.");
    });
  }};
const Contact = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <>
      <Loader />
      <div className="site_contain">
        {/* <!-- ====================================== Cusrsor ===================================== --> */}
        <Cursor />
        {/* <!-- ====================================== Cursor End ===================================== --> */}
        {/* <!-- ====================================== Header Menu ===================================== --> */}
      
        {/* <!-- ====================================== Header Menu End===================================== --> */}
        {/* <!-- ====================================== Conatct Section One ===================================== --> */}
        <section
          className="section-main contact-section-main overflow-hidden"
          id="section-main-id"
        >
          <div className="container">
            <p className="main-text my-services" data-aos="fade-down">
             ME JOINDRE
            </p>
            <h1 className="ui-ux" data-aos="fade-down">
              Faisons quelque chose d'exceptionnel ensemble!
            </h1>
          </div>
        </section>
        {/* <!-- ====================================== Conatct Section One End===================================== --> */}
        {/* <!-- ====================================== Conatct Section two ===================================== --> */}
        <section className="section-two-my-services">
          <div className="container" id="form-conatiner">
            <div className="row" id="form">
              <div className="col-xxl-8 col-xl-8 col-lg-8 ">
                <form className="form-main">
                  <div className="form-main-sub">
                    <div className="input-main-form">
                      <p className="name">Nom(s):</p>
                      <input
                        type="text"
                        name="name"
                        placeholder="Votre nom"
                        autoComplete="off"
                        required
                      />
                    </div>
                    <div className="input-main-form">
                      <p className="name">Email:</p>
                      <input
                        type="email"
                        name="name"
                        placeholder="Votre email"
                        autoComplete="off"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-main-sub">
                    <div className="input-main-form">
                      <p className="name">Téléphone (optionnel):</p>
                      <input
                        type="number"
                        name="name"
                        placeholder="Votre numéro"
                        autoComplete="off"
                        required
                      />
                    </div>
                    <div className="input-main-form">
                      <p className="name">Sujet:</p>
                      <input
                        type="text"
                        name="name"
                        placeholder="Sujet"
                        autoComplete="off"
                        required
                      />
                    </div>
                  </div>
                  <div className="input-main-form">
                    <p className="name">Message:</p>
                    <textarea placeholder="Votre message..."></textarea>
                  </div>
                  <div className="form-submit-btn-main">
                    <button className="explore-btn hover-slide-right form-submit-btn">
                      <span id="toggle">
                        ENVOYER
                        <svg
                          className="expo-arrow2"
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                        >
                          <g>
                            <path
                              d="M6.66669 16H25.3334"
                              stroke="#FFDB59"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M20 21.3333L25.3333 16"
                              stroke="#FFDB59"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M20 10.6666L25.3333 16"
                              stroke="#FFDB59"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </g>
                        </svg>
                      </span>
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 overflow-hidden">
                <div className="get-touch-box-main">
                  <h2>Gardons contact</h2>
                  <p className="well">
                    N'hésitez surtout pas de me contact pour échanger et donner vie à votre projet.
                  </p>
                  <div className="contact-footer contact-footer2">
                    <img src={PhoneSvg} alt="phone_svg" />
                    <Link to="tel:+242065298498">+242 06 529 84 98</Link>
                  </div>
                  <div className="contact-footer contact-footer2">
                    <img src={EmailSvg} alt="email_svg" />
                    <Link to="mailto:contact@eulogetabala.cg">contact@eulogetabala.cg</Link>
                  </div>
                
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- ====================================== Conatct Section Two End===================================== --> */}
        {/* <!-- ====================================== text Marquee Section  ===================================== --> */}

        <TextMarqueAboutSection />
        {/* <!-- ====================================== text Marquee Section End ===================================== --> */}
        {/* <!-- ====================================== Footer ===================================== --> */}
       
      </div>
    </>
  );
};
export default Contact;
