import React, { useEffect, useState } from "react";
import AOS from "aos";
import "../../../node_modules/aos/dist/aos.css";
import { Link } from "react-router-dom";

const SectionThree = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const handleAccordionClick = (index) => {
    setSelectedIndex(selectedIndex === index ? null : index);
  };

  const accordionData = [
    {
      title: "Développement de sites web et applications web",
      content:
        "Je propose le développement de sites et d'applications web interactifs et responsifs, allant des sites vitrines aux applications complexes :",
      listItems: [
        "Sites vitrines et portails web",
        "Applications web dynamiques",
        "Portails d'administration",
        "SEO et optimisation",
      ],
    },
    {
      title: "Développement d'applications mobiles",
      content:
        "Je conçois et développe des applications mobiles performantes et modernes sur iOS et Android.",
      listItems: [
        "Applications natives",
        "Intégration de fonctionnalités avancées",
        "Onboarding et expérience utilisateur",
        "Mise en œuvre de design responsif",
      ],
    },
    {
      title: "Conception et intégration d'API",
      content:
        "Je développe des API RESTful robustes et sécurisées pour connecter différents services et applications.",
      listItems: [
        "Conception d'API backend",
        "Intégration d'API tierces",
        "Tests d'API",
      ],
    },
    {
      title: "Déploiement et maintenance des applications",
      content:
        "Je gère l'intégralité du cycle de vie d'une application, depuis le déploiement initial jusqu'à la maintenance continue.",
      listItems: [
        "Déploiement sur le cloud",
        "Maintenance et support technique",
        "Surveillance et reporting",
      ],
    },
    {
      title: "Consulting et formation technique",
      content:
        "Je propose des services de conseil et de formation pour aider les entreprises.",
      listItems: [
        "Optimisation des architectures",
        "Revue de code",
        "Formation",
      ],
    },
    {
      title: "Design UX/UI et prototypage",
      content:
        "Je collabore avec les équipes de design pour créer des interfaces intuitives et attrayantes.",
      listItems: [
        "Création de maquettes",
        "Intégration des designs",
        "Expérience utilisateur",
      ],
    },
    {
      title: "Développement d'applications spécifiques",
      content:
        "Je développe des solutions sur mesure pour répondre à des besoins spécifiques.",
      listItems: [
        "Applications d'assurance",
        "Applications éducatives",
        "Applications de santé",
      ],
    },
  ];

  return (
    <>
      {/* <!-- ====================================== Section Three ===================================== --> */}
      <section className="section-three overflow-hidden">
        <div className="container">
          <div className="row services-section">
            <div className="col-xxl-4 col-xl-4 col-lg-4">
              <p className="our-services" data-aos="fade-up">
                MES SERVICES
              </p>
              <h2 className="our-solution" data-aos="fade-up">
                Les services que j'offre.
              </h2>
              <p className="uran odio" data-aos="fade-up">
                En tant qu'ingénieur logiciel avec plus de 5 ans d'expérience,
                voici les services que j'offre en détail.
              </p>
              <div className="services-circle-main">
                <div
                  className="view-work-circle btn btn1"
                  id="circle1"
                  data-aos="zoom-in-right"
                >
                  <Link to="/portfolio2" className="a1">
                    MES <br/>PROJETS
                  </Link>
                </div>
                <div className="contact-circle" data-aos="zoom-in-left">
                  <div className="view-work-circle btn btn2">
                    <Link to="/contact">ME CONTACTER</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-7 col-xl-7 col-lg-7">
              <div id="faq-sec">
                {accordionData.map((item, index) => (
                  <div
                    className="nested-accordion"
                    data-aos="fade-up"
                    key={index}
                  >
                    <h3
                      className={`boder-top ${
                        selectedIndex === index ? "selected" : ""
                      }`}
                      onClick={() => handleAccordionClick(index)}
                    >
                      {item.title}
                    </h3>
                    <div
                      className="comment"
                      style={{
                        display: selectedIndex === index ? "block" : "none",
                      }}
                    >
                      {item.content}
                      <ul className="accor-list">
                        {item.listItems.map((listItem, idx) => (
                          <li key={idx}>{listItem}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ====================================== Section Three End===================================== --> */}
      {/* <!-- ====================================== User Branding Marquee Section ===================================== --> */}
      <div className="header_full_sec overflow-hidden">
        <div className="logos">
          <div className="logos-slide">
            <div className="ticker__item">Développement web</div>
            <div className="ticker__item ticker__item_dark">
            Développement mobile
            </div>
            <div className="ticker__item">UI/UX Design</div>
            <div className="ticker__item ticker__item_dark">Consulting</div>
            <div className="ticker__item">Formation</div>
            <div className="ticker__item ticker__item_dark">
              Intégration API
            </div>
            <div className="ticker__item">Maintenance</div>
            <div className="ticker__item ticker__item_dark">Gestion Email</div>
            <div className="ticker__item">Wordpress</div>

          </div>
        </div>
      </div>
      {/* <!-- ====================================== User Branding Marquee Section End ===================================== --> */}
    </>
  );
};
export default SectionThree;
