import React, { useEffect } from "react";
import AOS from "aos";
import GirlImg2 from "../../assets/images/girl-img2.png";
import YearsImg from "../../assets/images/years-img.png";
import TrophyImg from "../../assets/images/trophy.png";
import { Link } from "react-router-dom";

const SectionTwo = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <>
      {/* <!-- ====================================== Section Two ===================================== --> */}
      <section className="section-two overflow-hidden" id="section-two">
        <div className="container">
          <div className="row section-two-row">
            <div
              className="col-xxl-6 col-xl-6 col-lg-6 position-relative section-two-col-one"
              data-aos="fade-up"
            >
              <img
                className="girl-img2"
                src={GirlImg2}
                alt="girl-img2"
                data-aos="fade-left"
              />
              <div className="hwlo">
                <img className="years-img" src={YearsImg} alt="years-img" />
                <div className="digit">5+</div>
                <div className="circle-text">
                  ANS
                  <br /> D'EXPERIENCE
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6">
              <div className="award-circle-main" data-aos="zoom-in">
                <div className="award-circle">
                  <img src={TrophyImg} alt="trophy" />
                </div>
                <div className="">
                  <h3 className="independent">Me découvrir un peu plus</h3>
                  <p className="annual">Qui suis-je?</p>
                </div>
              </div>
              <h5 className="self-taught" data-aos="fade-up">
                Je suis Euloge Tabala ingénieur logiciel avec plus de 5 ans
                d'expérience dans le développement de solutions numériques
                innovantes. Spécialisé dans la conception et la mise en œuvre de
                projets web et mobiles, je maîtrise divers frameworks et
                technologies pour créer des applications robustes et
                performantes. Mon expertise me permet de naviguer aisément à
                travers différentes phases du développement, tout en apportant
                une valeur ajoutée à chaque projet sur lequel je travail.
              </h5>
             
              <p className="explore-me" data-aos="fade-right">
                <Link to="../About/">
                  EN SAVOIR PLUS 
                  <svg
                    className="expo-arrow"
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                  >
                    <g>
                      <path
                        d="M6.66669 16H25.3334"
                        stroke="#FFDB59"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M20 21.3333L25.3333 16"
                        stroke="#FFDB59"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M20 10.6666L25.3333 16"
                        stroke="#FFDB59"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                  </svg>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ====================================== Section Two End ===================================== --> */}
    </>
  );
};
export default SectionTwo;
